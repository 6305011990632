import React from "react";

const Cities = (props) => {
    // Sample data containing the circle elements
    const circleData = [
        {
            cx: "-500.2",
            cy: "259.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"falkirk"
        },
        {
            cx: "-528.2",
            cy: "274.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"irvine"
        },
        {
            cx: "-511.2",
            cy: "266.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"cumbernauld"
        },
        {
            cx: "-516.2",
            cy: "268.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"east-kilbride"
        },
        {
            cx: "-512.2",
            cy: "271.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"hamilton"
        },
        {
            cx: "-477.2",
            cy: "306.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"carlisle"
        },
        {
            cx: "-489.2",
            cy: "330.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"dalton-in-furness"
        },
        {
            cx: "-487.2",
            cy: "336.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"barrow"
        },
        {
            cx: "-478.2",
            cy: "343.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"lancaster"
        },
        {
            cx: "-475.2",
            cy: "353.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"bamber-bridge"
        },
        {
            cx: "-470.2",
            cy: "360.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"horwich"
        },
        {
            cx: "-481.2",
            cy: "368.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west",
            id:"liverpool"
        },
        {
            cx: "-460.2",
            cy: "366.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west"
        },
        {
            cx: "-462.2",
            cy: "370.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west"
        },
        {
            cx: "-438.2",
            cy: "319.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"stockton"
        },
        {
            cx: "-443.2",
            cy: "319.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"darlington"
        },
        {
            cx: "-446.2",
            cy: "314.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"bishop-auckland"
        },
        {
            cx: "-446.2",
            cy: "309.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"durham"
        },
        {
            cx: "-441.2",
            cy: "309.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"peterlee"
        },
        {
            cx: "-444.2",
            cy: "304.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"houghton"
        },
        {
            cx: "-442.2",
            cy: "360.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test yorkshire-humberside",
            id:"pontefract"
        },
        {
            cx: "-444.2",
            cy: "363.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test yorkshire-humberside",
            id:"wakefield"
        },
        {
            cx: "-486.2",
            cy: "384.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"wrexham"
        },
        {
            cx: "-493.2",
            cy: "452.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"barry"
        },
        {
            cx: "-498.2",
            cy: "449.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"bridgend"
        },
        {
            cx: "-461.2",
            cy: "434.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"cheltenham"
        },
        {
            cx: "-463.2",
            cy: "439.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"stroud"
        },
        {
            cx: "-470.2",
            cy: "448.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"bristol"
        },
        {
            cx: "-468.2",
            cy: "456.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"trowbridge"
        },
        {
            cx: "-470.2",
            cy: "459.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"frome"
        },
        {
            cx: "-439.2",
            cy: "460.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"newbury"
        },
        {
            cx: "-442.2",
            cy: "446.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"oxford"
        },
        {
            cx: "-418.2",
            cy: "461.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"guildford"
        },
        {
            cx: "-383.2",
            cy: "459.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"maidstone"
        },
        {
            cx: "-376.2",
            cy: "462.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"faversham"
        },
        {
            cx: "-369.2",
            cy: "466.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"dover"
        },
        {
            cx: "-373.2",
            cy: "469.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"folkestone"
        },
        {
            cx: "-380.2",
            cy: "467.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"ashford"
        },
        {
            cx: "-391.2",
            cy: "452.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"gravesend"
        },
        {
            cx: "-387.2",
            cy: "446.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"purfleet"
        },
        {
            cx: "-414.2",
            cy: "432.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"hitchin"
        },
        {
            cx: "-410.2",
            cy: "425.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"st-neots"
        },
        {
            cx: "-400.2",
            cy: "415.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"doddington"
        },
        {
            cx: "-397.2",
            cy: "403.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"wisbech"
        },
        {
            cx: "-391.2",
            cy: "399.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"kings-lynn"
        },
        {
            cx: "-408.2",
            cy: "389.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"boston"
        },
        {
            cx: "-411.2",
            cy: "395.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"spalding"
        },
        {
            cx: "-425.2",
            cy: "392.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"grantham"
        },
        {
            cx: "-441.2",
            cy: "387.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"ilkeston"
        },
        {
            cx: "-499.2",
            cy: "265.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"livingston"
        },
        {
            cx: "-456.2",
            cy: "400.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test west-midlands",
            id:""
        },
        {
            cx: "-415.2",
            cy: "387.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"sleaford"
        },
        {
            cx: "-401.2",
            cy: "422.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"huntingdon"
        },
        {
            cx: "-392.2",
            cy: "466.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"tunbridge-wells"
        },
        {
            cx: "-488.2",
            cy: "448.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"newport"
        },
        {
            cx: "-508.2",
            cy: "442.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"morriston"
        },
        {
            cx: "-513.2",
            cy: "445.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"swansea"
        },
        {
            cx: "-594.2",
            cy: "303.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test northern-ireland",
            id:"derry-londonderry"
        },
        {
            cx: "-428.2",
            cy: "440.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"aylesbury"
        },
        {
            cx: "-473.2",
            cy: "365.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west"
        },
        {
            cx: "-521.2",
            cy: "272.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"kilmarnock"
        },
        {
            cx: "-451.2",
            cy: "312.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "north-east test",
            id:"spennymoor"
        },
        {
            cx: "-443.2",
            cy: "356.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test yorkshire-humberside",
            id:"castleford"
        },
        {
            cx: "-468.2",
            cy: "374.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test north-west"
        },
        {
            cx: "-453.2",
            cy: "403.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test west-midlands",
            id:"walsall"
        },
        {
            cx: "-458.2",
            cy: "404.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test west-midlands",
            id:"wednesbury"
        },
        {
            cx: "-460.2",
            cy: "393.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test west-midlands",
            id:"stafford"
        },
        {
            cx: "-490.2",
            cy: "453.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales"
        },
        {
            cx: "-493.2",
            cy: "447.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"hengoed"
        },
        {
            cx: "-506.2",
            cy: "268.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"coatbridge"
        },
        {
            cx: "-494.2",
            cy: "253.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"dunfermline"
        },
        {
            cx: "-486.2",
            cy: "252.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"kirkcaldy"
        },
        {
            cx: "-519.2",
            cy: "442.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"Llanelli"
        },
        {
            cx: "-506.2",
            cy: "447.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test wales",
            id:"neath"
        },
        {
            cx: "-443.2",
            cy: "450.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"didcot"
        },
        {
            cx: "-415.2",
            cy: "458.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"byfleet"
        },
        {
            cx: "-401.2",
            cy: "435.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"hoddesdon"
        },
        {
            cx: "-372.2",
            cy: "458.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"canterbury"
        },
        {
            cx: "-563.2",
            cy: "322.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test northern-ireland",
            id:"belfast"
        },
        {
            cx: "-395.2",
            cy: "462.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-east",
            id:"sevenoaks"
        },
        {
            cx: "-485.2",
            cy: "262.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test scotland",
            id:"musselburgh"
        },
        {
            cx: "-453.2",
            cy: "367.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"mansfield"
        },
        {
            cx: "-451.2",
            cy: "371.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"sutton-in-ashfield"
        },
        {
            cx: "-447.2",
            cy: "387.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"langley-mill"
        },
        {
            cx: "-457.2",
            cy: "385.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-midlands",
            id:"swadlincote"
        },
        {
            cx: "-421.2",
            cy: "428.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test east-anglia",
            id:"bedford"
        },
        {
            cx: "-470.2",
            cy: "427.96",
            r: "2.22",
            fill: "#c12a87",
            stroke: "#fff",
            "stroke-miterlimit": "10",
            "stroke-width": "0.5",
            className: "test south-west",
            id:"tewekesbury"
        }
    ];
    let filteredCircles = [];
    // Filter the circles by class
    if (props.filterData) {
        filteredCircles = circleData.filter(circle => circle.className.includes(props.filterData));
    } else {
        filteredCircles = circleData;
    }

    return (
        <>
            {/* Render the filtered circles */}
            {filteredCircles.map((circle, index) => (
                <circle key={index} {...circle} />
            ))}
        </>
    );
};

export default Cities;
